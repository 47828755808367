
[data-uppy-theme=dark] .uppy-Dashboard-inner,
  .uppy-DashboardContent-bar,
  .uppy-StatusBar-actions,
  .uppy-Dashboard-AddFiles {
  background-color: var(--phoenix-primary-bg-subtle) !important;
}

[data-uppy-theme=dark] {
  --uppy-dark-border-color: #2d323f;
}

[data-uppy-theme=dark] .uppy-Dashboard-inner {
  border: 1px solid var(--uppy-dark-border-color);
}

[data-uppy-theme=dark] .uppy-DashboardContent-bar {
  border-bottom: 1px solid var(--uppy-dark-border-color) !important;
}

[data-uppy-theme=dark] .uppy-StatusBar {
  border-top: 1px solid var(--uppy-dark-border-color) !important;
}

[data-uppy-theme=dark] .uppy-Dashboard-AddFilesPanel {
  background-color: var(--phoenix-white) !important;
  background-image: linear-gradient(0deg,
  var(--phoenix-white) 35%,
  var(--phoenix-white) 100%
  ) !important;
}

.fancybox__container {
  z-index: 1060 !important;
}
